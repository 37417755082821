import React, { FC, Ref, forwardRef } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ThemeSpaceKeys } from '../../styles/tokens/space';

export interface StackProps
  extends React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> {
  gap?: ThemeSpaceKeys;
  as?: string | React.ElementType;
  asGrid?: boolean;
  direction?: 'vertical' | 'horizontal';
}

const StyledStack = styled.div<{
  gap?: ThemeSpaceKeys;
  asGrid?: boolean;
  direction?: 'vertical' | 'horizontal';
}>`
  ${({ theme: { space }, gap, asGrid, direction = 'vertical' }) => css`
    ${asGrid
      ? css`
          display: grid;
          ${direction === 'horizontal'
            ? css`
                grid-auto-flow: column;
                grid-column-gap: ${space[gap || 'fluidMedium']};
                grid-template-columns: auto 1fr;
                > *:first-of-type {
                  /* width: max-content; */
                }
              `
            : css`
                grid-auto-flow: row;
                grid-row-gap: ${space[gap || 'fluidMedium']};
              `}
        `
      : css`
          ${direction === 'horizontal'
            ? css`
                display: flex;
                > * + * {
                  margin-left: ${space[gap || 'fluidMedium']};
                }
              `
            : css`
                > * + * {
                  margin-top: ${space[gap || 'fluidMedium']};
                }
              `}
        `}
  `};
`;
// Your existing Stack component
const StackComponent = (
  { children, gap, as, asGrid, ...rest }: StackProps,
  ref: Ref<HTMLDivElement>
) => {
  const StyledStackAs = as
    ? StyledStack.withComponent(as as React.ComponentType<any>)
    : StyledStack;
  return (
    <StyledStackAs ref={ref} gap={gap} asGrid={asGrid} {...rest}>
      {children}
    </StyledStackAs>
  );
};

// Wrap the existing Stack component with forwardRef
const Stack = forwardRef<HTMLDivElement, StackProps>(StackComponent);

export { Stack, StyledStack };
Stack.displayName = 'Stack';
export default Stack;
